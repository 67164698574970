import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../../components/Layout';
import Cover from '../../../components/Elements/Cover';
import PageSection from '../../../components/Elements/PageSection';
import Row, { Col } from '../../../components/Elements/Grid';

import GetReady from '../../../components/Parts/GetReady';
import withTranslations from '../../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('onzeAanpakCases');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover image="avocado">
        <PageSection center>
          <h1>{t('title')}</h1>
          <Row>
            <Row>
              <Col video>
                <h2 className="text-center">Future Store Arnhem</h2>
                <iframe
                  title="Future Store Arnhem"
                  src="https://player.vimeo.com/video/385966160"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  sandbox="allow-same-origin allow-scripts"
                />
                <p>{t('cases.futureStore.p1')}</p>
                <p>{t('cases.futureStore.p2')}</p>
              </Col>
            </Row>
            <Col video>
              <h2 className="text-center">Greenberry</h2>
              <iframe
                title="Greenberry"
                src="https://player.vimeo.com/video/363534758"
                frameBorder="0"
                allow="autoplay; fullscreen"
                sandbox="allow-same-origin allow-scripts"
              />
              <p>{t('cases.greenberry.p1')}</p>
              <p>{t('cases.greenberry.p2')}</p>
              <p>{t('cases.greenberry.p3')}</p>
            </Col>
          </Row>
        </PageSection>
        <GetReady title={t('getReadyTitle')} image="footerCases" />
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
